import promoStatus from "@/config/promoStatus";
import errorMessages from "@/assets/errorMessages.json";
import {mask} from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  data: function () {
    return {
      domain_url: process.env.VUE_APP_BASE_URL,
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },

  watch: {},
  computed: {
    promotionIsActive() {
      return this.$store.state.promoStatus === promoStatus.live;
    },
    promotionIsBefore() {
      return this.$store.state.promoStatus === promoStatus.before;
    },
    promotionIsAfter() {
      return this.$store.state.promoStatus === promoStatus.after;
    },
    jatekszabalyTimeStamp() {
      return Math.floor(Date.now() / 1000);
    }
  },
  methods: {
    tr(key,search,replace){
      //console.log('tr',key,search,replace);
      if (this.$store.state.langText){
          if (this.$store.state.langText[key]){
             
              return String(this.$store.state.langText[key]).replace(search,replace)
          }

          return "no text available " + key
      } else {
          return "no text available"
      }
    },
    getErrorMessage(fieldName, violation) {
      
      let langCode = this.$store.state.currentLang
      return violation ? (errorMessages[langCode][fieldName] &&
              errorMessages[langCode][fieldName][violation[0]]) ??
          violation[1]
          : undefined;
    },
    GTtrackGA4(dataLayerOptions) {
      //console.log("GTtrackGA4 track " , dataLayerOptions);
      try {
        window.dataLayer.push(dataLayerOptions);
      } catch (e) {
        console.log("not found dataLayer")
      }
    },
    setErrorMsgs(err, refObserver){            
      let langCode = this.$store.state.currentLang
      let _this = this
        return new Promise((resolve, reject) => {
          if (err) {            
            var ret  = {};
            for (var i in err.response.data.error){                         
              ret[i] = errorMessages[langCode][i][err.response.data.error[i]]
            }                                                  
            _this.$refs[refObserver].setErrors(ret);              
            resolve()
          } else {
            reject()
          }
          
        });
    },
    scrollToElement(element, diff){
            
        const offset = document.querySelector('.navbar').clientHeight + diff
        const bodyTop = document.body.getBoundingClientRect().top;
        const elementTop = element.getBoundingClientRect().top;
        const elementPos = elementTop - bodyTop;
        const offsetPos = elementPos - offset;

        window.scrollTo({
            top: offsetPos,
            behavior: 'smooth'
        });
    },
  }
}
