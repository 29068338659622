<template>
  <div class="emailInfo-container page-block" id="kapcsolat">
    <h2 class="text-center text-primary"> {{tr('contact-title')}}</h2>
    <div class="col-12 col-lg-6 offset-lg-3">
      <p class="text-center mb-5 text-primary" v-html="tr('contact-text')"></p>
    </div>
    <div class="container-fluid justify-content-center row m-0">
      <div class="m-a col-12 col-md-6">        
        <validationObserver
            v-if="!formStatus"
            v-slot="{ handleSubmit }"
            tag="div"
            ref="contactObserver"
        >
          <div class="">
            <form id="contactForm"
                class="form form--codefilling"
                @submit.prevent="handleSubmit(formSubmit)"                
            >
              <div class="form-row w-100 input--info">
                <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules, errors }" tag="div" vid="forename">
                    <div class="d-flex align-itemx-center">
                        <div class="form-label">{{tr('main-form-keresztnev')}}<span class="ml-1 error">*</span></div>
                        <span
                            class="icon-info"
                            data-toggle="tooltip"
                            data-placement="top"
                            :title="tr('main-form-keresztnev-tooltip')"
                        ></span>                    
                    </div>
                    <input
                        v-model="form.forename"
                        type="text"
                        :placeholder="tr('main-form-keresztnev-placeholder')"
                        class="form-control"
                        name="forename"                        
                        id="forname"
                        tabindex="7"
                    />
                    <span class="error" v-if="getErrorMessage('forname', Object.entries(failedRules)[0])">{{
                        getErrorMessage("forname", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                  <ValidationProvider :rules="{ min: 2, required: true}" v-slot="{ failedRules, errors }" tag="div" vid="surname">
                    <div class="d-flex align-itemx-center">
                      <div class="form-label">{{tr('main-form-vezeteknev')}}<span class="ml-1 error">*</span></div>
                      <div
                          class="icon-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="tr('main-form-vezeteknev-tooltip')"
                      ></div>                  
                    </div>
                    <input
                        v-model="form.surname"
                        type="text"
                        class="form-control"
                        name="surname"
                        :placeholder="tr('main-form-vezeteknev-placeholder')"
                        id="surname"
                        tabindex="8"
                    />
                    <span class="error" v-if="getErrorMessage('surname', Object.entries(failedRules)[0])">{{
                        getErrorMessage("surname", Object.entries(failedRules)[0])
                      }}</span>
                      <span class="error" v-else>{{errors[0]}}</span>   
                  </ValidationProvider>
              </div>
              <div class="form-row w-100 input--info">
                <div class="email-row input--info">

                    <ValidationProvider
                        rules="required|email"
                        v-slot="{ failedRules, errors }"
                        vid="email"
                    >                  
                    <label for="recipient-name" class="form-label">{{tr('contact-input-email')}}<span class="ml-1 error">*</span></label>
                    <input v-model="form.email" :placeholder="tr('contact-email-placeholder')" type="text"
                            class="form-control email-placeholder" tabindex="9"
                            id="recipient-name">
                    <span class="error" v-if=" getErrorMessage('email', Object.entries(failedRules)[0])">{{
                        getErrorMessage("email", Object.entries(failedRules)[0])
                        }}</span>
                    <div class="error" v-else>{{errors[0]}}</div>
                    </ValidationProvider>
                </div>
                <div class="input--info phone-row">                  
                    <div class="form-label">{{tr('main-form-phone')}}</div>                  
                    <input
                        v-model="form.phone"
                        type="text"
                        :placeholder="tr('main-form-phone-placeholder')"
                        class="form-control"
                        name="phone"
                        id="phone"
                        ref="phone"
                        tabindex="10"
                    />      
                </div>   
            </div> 
              <ValidationProvider
                  rules="required"
                  v-slot="{ failedRules, errors }"
                  class="message-row"
                  vid="message"
              >
                <label for="message-text" class="form-label mt-4 ">{{tr('contact-input-msg')}}<span class="ml-1 error">*</span></label>
                <textarea type="text" v-model="form.message" :placeholder="tr('contact-msg-placeholder')"
                          class="form-control textarea py-3" tabindex="11"
                          id="message-text">
                </textarea>
                <span class="error" v-if="getErrorMessage('message', Object.entries(failedRules)[0])">{{
                    getErrorMessage("message", Object.entries(failedRules)[0])
                  }}</span>
                <div class="error" v-else>{{errors[0]}}</div>
              </ValidationProvider> 
                <div class="custom-control custom-checkbox checkbox-margin condition-row pt-3">
                <ValidationProvider
                      rules="required|acceptConditions"
                      v-slot="{ failedRules, errors }"
                      tag="div"
                      vid="privacy"
                  >
                    <input
                        true-value="1"
                        false-value="0"
                        v-model="form.condition"
                        type="checkbox"
                        class="custom-control-input"
                        id="conditions-contact"
                        name="condition"
                    />
                    <label
                        class="custom-control-label mb-0 condition-label"
                        for="conditions-contact"
                    >
                      <div>
                        <p>
                          Hozzájárulok, hogy megadott adataimat a Kapcsolatfelvétel során az adatkezelő kezelje. Bővebb információ az<a class='link' :href="$store.state.privacy_url" target='_blank'>Adatkezelési tájékoztatóban.</a><span style='text-decoration:none; color: red;'>*</span>
                        </p>
                      </div>

                    </label>
                    <div class="mt-4"></div>
                    <span class="error" v-if="getErrorMessage('privacy', Object.entries(failedRules)[0])">{{
                        getErrorMessage("privacy", Object.entries(failedRules)[0])
                      }}</span>
                    <span class="error" v-else>{{errors[0]}}</span>  
                  </ValidationProvider>
                </div>
                <p><span class="text-red" style="font-size: 14px;">*</span> A csillaggal jelölt mezők kitöltése szükséges.</p>


              <div v-if="!formStatus" class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn btn-primary " :disabled="formSubmitted">{{tr('contact-btn')}}</button>
              </div>
            </form>
          </div>
        </validationObserver>

        <!-- FORM RESULT -->
        <!-- <p v-if="sendingError" class="error error--send alert alert-danger mt-3">{{tr('contact-form-result-unsuccess')}}</p> -->
        <div v-if="formStatus === true" class="contact__result-block text-center">
          <img src="@/assets/img/icons/ic-success-contact.png" width="150" />
          <h2 class="text-center my-3" style="font-size: 28px;">
            {{tr('contact-form-result-success')}}
          </h2>          
          <div class="d-flex justify-content-center" >
            <button type="button" class="btn btn-primary m-auto" @click="defaultFormState(); formStatus = false">
              {{tr('contact-form-back')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import {extend} from "vee-validate";
import $ from "jquery";


extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  },
  // message: "A Játékszabályzatot kötelező elfogadnia. ",
});

export default {
  name: "emailInfo",
  mixins: [apiHandlerMI],
  data() {
    return {
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
      },
      privacy_url: "",
      sendingError: false,
      formStatus: false,
      form: {
        condition: 0,
        topic: null,
        phone: "",
        email: "",
        forename: "",
        surname: "",
        message: null,        
        // condition: '0',
      },
      formSubmitted: false,
      baseUrl: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  }, 
  mounted(){
    this.$store.dispatch("getFileUrl");    
  },
  methods: {
    backToMain() {
      window.location.href = "/";
    },
    removeError(fieldName) {
      $(`#contactModal .${fieldName}-row .error-2`).remove();
    },
   
    defaultFormState() {
      let _this = this

      _this.form.forename = '';
      _this.form.surname = '';
      _this.form.email = "";
      _this.form.phone = "";
      _this.form.condition = 0;
      _this.form.topic = null;
      _this.form.message = null;      
      _this.formSubmitted = false
    },    
    formSubmit() {

      var _this = this;      

      if (!_this.formSubmitted) {
        _this.formSubmitted = true

        _this.$axios
            .post(process.env.VUE_APP_API_URL + 'message', {
              forename: this.form.forename,
              surname: this.form.surname,
              email: this.form.email,
              phone: this.form.phone,
              condition: this.form.condition,
              message: this.form.message,
              topic: this.form.topic
            })
            .then((response) => {
              // console.log(response.data)
              
              if (response.data.status === true) {
                //show the win or not win block instead of the codefilling form
                _this.formStatus = true;
                _this.defaultFormState()
              
              } else {
                $(".form-subtitle").append(
                    '<div class="error-2">Hiba történt, kérjük, próbáld újra.</div>'
                );
                
              }

              _this.formSubmitted = false

            })
            .catch(function (error) {
              console.log(error);
              //_this.sendingError = true
              _this.formSubmitted = false

              _this.goToError(error)  

            });

      } else {
        console.log('double form submit')
      }

    },
     goToError(err){            
        let _this = this
        //scroll to the first error
        console.log('helo')
        this.setErrorMsgs(err, 'contactObserver').then(()=>{                               
          let firstError = document.querySelector('#contactForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }
          //document.getElementById('promotionCodeForm').scrollIntoView(true);
        })                                       
    },
  }  
};
</script>
