<template>
  <div class="winners page-block position-relative" id="winners-block">
    <div class="container-xl">
      <div class="row text-center mb-4">
          <div class="col-12 col-lg-8 offset-lg-2 text-white">
            <h2 class="text-yellow mb-4">{{tr('winners-title')}}</h2>
            <p>Kedves Játékosunk!</p>
            <p>A heti jégkrémcsomagok nyertesei 2023. július 19., 2023. július 26., 2023. augusztus 2., 2023. augusztus 9. napokon kerülnek kihirdetésre a Lebonyolító által küldött e-mail üzenetben.</p>
            <p>Ne feledd, nyertessé csak kifizetett és átvett megrendeléssel válhatsz!</p>
          </div>
          <div class="col-12 col-lg-8 offset-lg-2 mt-4">
            <img :src="`${baseUrl}/lang_images/winner-product.png`" :alt="tr('winnings-img-alt-heti')" class="img-fluid product-img" />
            <p class="text-white">A képek csak illusztrációk.</p>
          </div>
        </div>
           
            <!-- NYertesek -->
            <div class="row">     
                <div class="col-12 col-md-10 offset-md-1">
                    <!-- TAB -->
                    <div class="winners-button-select mb-5" >
                        <button 
                            class="btn fw-700" style="border-radius: 11px;"
                            :class="{'bg-white text-primary':winnerState === 'main'}" 
                            v-on:click="setWinnerState('main')"
                        >
                            Fődíj
                        </button>
                        <button 
                            class="btn"
                            :class="{'bg-white text-primary':winnerState === 'weekly'}" 
                            v-on:click="setWinnerState('weekly')"
                        >
                            Heti <span class="d-none d-md-block ms-1">&nbsp;nyertesek</span>
                        </button> 
                       <!--<button 
                            class="btn"
                            :class="{'bg-secondary text-primary':winnerState === 'napi'}" 
                            v-on:click="setWinnerState('napi')"
                        >
                            Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                        </button>-->
                        
                    </div>
                    <!-- TABLE -->
                    <div class="winners__border mb-5">
                        <div class="winners__table-wrap winners__table-wrap--heading p-3">                        
                            <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                <thead>
                                    <tr class="winners-top text-primary">
                                        <th class="w-50">Nyertes neve</th>
                                        <th class="w-50">Város</th>                            
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="winners__table-wrap winners__table-wrap--bg font-secondary">                        
                            <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                <tbody v-if="filteredWinners && filteredWinners.length && loginShow">                          
                                    <tr class="winners-list" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                        <td class="winners-id w-50 p-3">
                                            <div><strong>{{ winner.name }}</strong></div>                                     
                                        </td>                          
                                        <td class="winners-product text-right text-md-left w-50 py-3 pr-3 pr-lg-0">
                                            <span>{{winner.winning_time}}</span>
                                        </td>
                                    </tr>                            
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="loginShow">
                                        <td v-if="winnerState != 'main'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül kik lettek a nyertesek
                                        </td>
                                        <td v-if="winnerState == 'main'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül ki lett a fődíj nyertese
                                        </td>
                                        <!--<td v-if="winnerState != 'fodij'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül kik lettek a nyertesek
                                        </td>
                                        <td v-if="winnerState == 'fodij'" class="no-winner text-center py-3" colspan="2">
                                            Hamarosan kiderül ki lett a fődíj nyertese
                                        </td>-->
                                    </tr>
                                    <tr v-else>
                                        <td class="no-winner text-center py-3" colspan="2">
                                            A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                        
                        </div>
                        
                    </div>
                    <div class="d-flex justify-content-center pt-4" v-if="needMoreBtn">
                        <button target="_blank" type="button" class="btn btn-secondary" v-on:click="moreWinners(true)">Mutasd a korábbi nyerteseket</button>
                    </div>
                </div>           
            </div> 

        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {
            loginShow: 1,
            winnerState:'weekly',
            needMoreBtn: false,
            needMoreWinners: false
        }
    },
    computed:{
        filteredWinners() {
            let result;
        
            if(this.$store.state.winnersAll ?? []){
                result = this.$store.state.winnersAll.filter(w => w.type === this.winnerState)

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            console.log(result);
            return result;
        }
    },
    methods: {
        setWinnerState(newState) {
            this.winnerState = newState;     
            this.moreWinners(false); 
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        }
    },
    created() {
      this.$store.dispatch("getWinners");    
    },
    
}
</script>