import Vue from 'vue'
import VueRouter from 'vue-router'

import APCodeUpload from '@/views/APCodeUpload.vue'
import Winner from '@/views/Winner.vue'
// import Impressum from '@/views/Impressum.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'APCodeUpload',
    component: APCodeUpload
  },
  {
    path: '/nyeremeny',
    name: 'Winner_hu',
    component: Winner
  }, 
  {
    path: '/invingatori',
    name: 'Winner_ro',
    component: Winner
  },  
  {
    path: '/vitezove',
    name: 'Winner_cz',
    component: Winner
  },  
  {
    path: '/vitazov',
    name: 'Winner_sk',
    component: Winner
  },   
  {    
    path: "*",
    redirect: '/'         
  }    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {  
    return { x: 0, y: 0 }
  }
})


// const router = new VueRouter({
//   routes
// })


export default router
