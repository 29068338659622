<template>
  <div id="app">
    <Header/>    
    <transition name="changePage" mode="out-in">
      <router-view/>
    </transition>
    <Footer/>    
    
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  components: {
    Header,
    Footer    
  },  
  watch: {
    $route() {      
         window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
        });   
    }
  },
  created(){    
    this.$store.dispatch("getText")
    this.$store.dispatch("getShops")
  }
}
</script>