<template>
    <div id="termekek" class="products pb-5 pt-2 position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 col-lg-8 offset-lg-2 d-flex flex-column text-center pt-md-5">
                    <h2 class="font-header text-center mt-3 pt-3 pt-lg-0">
                        Részt vevő termékek
                    </h2>
                    <div class="products-container__inner">
                        <p class="text-black font-secondary fw-bold">A Játékban részt vesz a Family Frost weboldalán található összes termék <a href="https://www.frost.hu" target="_blank" class="link">(www.frost.hu).</a></p>
                    </div>
                </div>
                <div class="col-12 d-flex flex-wrap justify-content-center align-items-center" style="gap:20px;">
                    <img :src="`${baseUrl}/lang_images/product1.png`" class="img-fluid py-4">
                    <a class="btn btn-primary mt-4 mt-md-0" href="https://www.frost.hu" target="_blank">Vásárolok</a>
                </div>
                
               <!-- <div class="col-12 text-center mt-5">
                    <a class="btn btn-primary me-md-4 mb-3 mb-md-0" @click="setGtm('Megveszem')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>   -->
                <div class="col-12 mt-5">
                    <div class="d-flex flex-column">
                        <p class="text-center text-black pt-3">A képek csak illusztrációk!</p>
                    </div>
                </div>
            </div>         
        </div>
    </div>
</template>

<script>
export default {
    methods: {   
        /*setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }*/
    }
}
</script>