// import Vue from 'vue'
//cookie
import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'

// const CBOptions = {
//     arroIcon: 'image/arrow-down.svg'
//   };

const CookieBanner = {      
    // install(Vue, options) {
    install(Vue) {       

        Vue.component("CookieBannerComp", CookieBannerComp)
    }
  }
  
  export default CookieBanner
  