<template>
    <div class="footer footer-main py-5">
        <div class="text-light text-center container-xl d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <p class="mb-4 mb-lg-0 text-white">© 2023 Family Frost Kft. - Minden jog fenntartva.</p>
            <a 
                :href="this.$store.state.privacy_url" 
                target="_blank" 
                class="mb-4 mb-lg-0 text-decoration-underline"
            >
                Adatkezelési tájékoztató
            </a>
            <a class="text-decoration-underline" href="mailto:frost@a4c.hu">frost@a4c.hu</a>
            <a href="#" v-scroll-to="'#hero'" class="scroll-up mt-4 mt-md-0"></a>
        </div>        
        <div>            
           <CookieBannerComp 
            class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>
        </div>
    </div>
</template>


<script>
import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'

export default {
    components: {
        CookieBannerComp        
    }
}
</script>