<template>
    <div class="questions" id="gyik">
        <div class="container">
            <h2 class="yellow">Kérdésed van?</h2>
            <div class="row pt-4">
                <div class="col-12 col-md-10 col-xl-10 offset-md-1 offset-xl-1">                    
                    <div class="questions-wrap">
                        <div class="questions__item" v-for="(question, index) in questions" :key="index">
                            <div @click="clickFAQ(question);" class="question collapsed" data-toggle="collapse" :data-target="'#answer_' + index" >{{ question.question }}</div>
                            <div v-html="question.answer" class="answer collapse" :id="'answer_' + index"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        clickFAQ(question){
            if (question.opened == false){
                question.opened = true;
            } else {
                question.opened = false;
            }
        }
    } ,
  mounted() {
    this.$store.dispatch("getQuestions");
    //privacy policy link - hack
    setTimeout(() => {
        document.getElementById('faq_gameRules').setAttribute('href', this.$store.state.privacy_url)
    }, 1000);
  },
  computed:{
    questions(){
      return this.$store.state.questions;
    },
  },  
}
</script>