import Vue from 'vue'
import App from './App.vue'
import router from './config/router.js'
import store from './config/store.js'
import globalMI from './mixins/globalMI.js';
import apiH from '@/mixins/apiHandlerMI.js'
import axios from 'axios'

//CSS
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/scss/main.scss'

import CookieBanner from '@/plugins/cookiebanner/cookie-banner.js'
import VueCookies from 'vue-cookies'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

Vue.prototype.$axios = axios

import Vuebar from 'vuebar';
Vue.use(VueCookies)
Vue.use(CookieBanner)
Vue.use(Vuebar);
Vue.mixin(globalMI)
Vue.mixin(apiH)

import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 60,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

