export const apiUrl = process.env.VUE_APP_API_URL;
export const apiUrlNoProject = process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME;

export const defaultLanguageId = 1;
//export const defaultLanguageCode = 'ro';
export const defaultLanguageCode = process.env.VUE_APP_LANG
//export const defaultLanguageCode = 'ro'
export const needLanguage =  false;
export const languages = {
    1: {
        id: 1,
        code: "hu"        
    },
    2: {
        id: 2,
        code: "ro"        
    },
    3: {
        id: 3,
        code: "cz"        
    },
    4: {
        id: 4,
        code: "sk"        
    },
   
};


